import midway from './midway';
import nou from './nou';
import unity from './unity';
import town from './town';
import trend from './trend';
import unbox from './unbox';
import stay from './stay';
import print from './print';
import link from './link';
import hub from './hub';

const PROJECT_NAMES = {
    Nou: "Nou",
    // Town: "Town",
    // Trend: "Trend",
    Hub: "Hub",
    Link: "Link",
    Unbox: "Unbox",
    Unity: "Unity",
    Midway: "Midway",
    Stay: "Stay",
    Print: "Print",
}

export const projects = new Map([
    // [PROJECT_NAMES.Nou, nou],
    // [PROJECT_NAMES.Town, town],
    // [PROJECT_NAMES.Trend, trend],
     [PROJECT_NAMES.Hub, hub],
    [PROJECT_NAMES.Link, link],
    [PROJECT_NAMES.Unbox, unbox],
    [PROJECT_NAMES.Unity, unity],
    [PROJECT_NAMES.Midway, midway],
    [PROJECT_NAMES.Stay, stay],
    [PROJECT_NAMES.Print, print],
    [PROJECT_NAMES.Link, link],
])